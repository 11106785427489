import { useQuery } from "@tanstack/vue-query";
import { useApi } from "~/composables/useApi";
import camelize from "camelcase-keys";

export const nfcQueries = {
  detail: (nfcId: MaybeRef<string>) => ({
    queryKey: ["nfc", nfcId, "detail"],
    queryFn: async () => {
      const api = useApi();
      const data = await api<NfcResponse>(`/v1/nfc/${toValue(nfcId)}`);
      return camelize(data, { deep: true });
    },
  }),
  memberShirts: (memberId: MaybeRef<number>) => ({
    queryKey: ["memberShirts", memberId],
    queryFn: async () => {
      const api = useApi();
      const data = await api<MemberShirt[]>(
        `/v1/nfc/memberShirts/${toValue(memberId)}`,
      );
      return camelize(data, { deep: true });
    },
  }),
  multipleDetails: (nfcIds: MaybeRef<string[]>) => ({
    queryKey: ["nfc", "multiple", nfcIds],
    queryFn: async () => {
      const api = useApi();
      const promises = toValue(nfcIds).map((id) =>
        api<NfcResponse>(`/v1/nfc/${id}`),
      );
      const responses = await Promise.all(promises);
      return responses.map((data) =>
        data.nfcData ? camelize(data.nfcData, { deep: true }) : null,
      );
    },
  }),
};

export const useNfcQuery = (id: string) => {
  return useQuery(nfcQueries.detail(id));
};

type Metrics = {
  impact: number;
  donated: number;
};

type AdditionalMetric = {
  impact: number;
  donated: number;
  contributors: number;
};

export type NfcData = {
  id: number;
  memberId: number;
  campaignId: number;
  dollarValue: number;
  position: number;
  createdAt: string;
  updatedAt: string;
  nfcId: string;
  memberName: string;
  ownMetrics: Record<string, Metrics>;
  additionalMetrics: Record<string, AdditionalMetric>;
};

type NfcResponse = {
  hasBeenClaimed: boolean;
  nfcData: NfcData | null;
};

type MemberShirt = {
  id: number;
  nfc_id: string;
  member_id: number;
  created_at: string;
  updated_at: string;
};
