type NfcCampaignVariant = {
  title: {
    text: string;
    textSize: string;
    textAlign: string;
    textColor?: string;
    textShadowColor?: string;
  };
  cta: {
    value: string;
  };
  action: {
    textAlign: string;
    text: string;
    textSize: string;
    font: string;
    topPositioning: string;
    leftPositioning: string;
    textColor?: string;
    textShadowColor?: string;
    textShadowClass?: string;
    textOutlineClass?: string;
  };
};
export const nfcCampaignVariants: Record<number, NfcCampaignVariant> = {
  3: {
    title: {
      text: "Tree Shirt",
      textSize: "text-[49px] whitespace-nowrap leading-none",
      textAlign: "text-center",
    },
    cta: {
      value: "Plant More Trees!",
    },
    action: {
      textAlign: "text-center",
      text: "Trees!",
      textSize:
        "text-[29px] whitespace-nowrap leading-none font-black uppercase",
      font: "font-Agrandir",
      textOutlineClass: "text-outline-mobile",
      topPositioning: "top-[0.070em]",
      leftPositioning: "left-[0.07em]",
    },
  },
  4: {
    title: {
      text: "Sea Shirt",
      textSize: "text-[49px] whitespace-nowrap leading-none",
      textAlign: "text-center",
      textColor: "text-blue-pale-azure",
      textShadowColor: "text-blue-deep",
    },
    action: {
      text: "Lbs To be removed!",
      textSize:
        "text-[24px] whitespace-nowrap leading-none font-black uppercase",
      textAlign: "text-center",
      font: "font-Agrandir",
      textColor: "text-blue-pale-azure",
      textShadowColor: "text-blue-deep",
      topPositioning: "top-[8px]",
      leftPositioning: "left-[0.07em]",
    },
    cta: {
      value: "Remove More Plastic!",
    },
  },
};

export type NfcCampaignShirtBadgeListItem = {
  bgColor: string;
  imageUrl: string;
  altImageText: string;
};

export type NfcCampaignShirtModalTitle = {
  text: string;
  textColor: string;
  textShadowColor: string;
};

export type NfcCampaignShirtModalContent = {
  shirtLabel: string;
  progressBarFillColor: string;
  shirtTitleStyle: NfcCampaignShirtModalTitle;
};

export type NfcCampaignShirtModalImpact = {
  impactNumber: {
    text: string;
    textColor?: string;
    textShadowColor?: string;
  };
  impactLabel: {
    text: string;
    textColor?: string;
    textShadowColor?: string;
  };
};

export type NfcCampaignShirtVariant = {
  badge: NfcCampaignShirtBadgeListItem;
  modal: {
    number: string;
    content: NfcCampaignShirtModalContent;
    impact: NfcCampaignShirtModalImpact;
  };
};

export const nfcCampaignShirtVariants: Record<number, NfcCampaignShirtVariant> =
  {
    3: {
      badge: {
        bgColor: "bg-aquamarine",
        imageUrl: "/images/profile-shirts-badges/shirt-tree.png",
        altImageText: "Tree Shirt Icon",
      },
      modal: {
        number: "02028",
        content: {
          shirtLabel: "Tree-Shirt",
          progressBarFillColor: "bg-green-lawn",
          shirtTitleStyle: {
            text: "Tree Shirt",
            textColor: "text-green-lawn",
            textShadowColor: "text-orange-flame",
          },
        },
        impact: {
          impactNumber: {
            text: "22",
          },
          impactLabel: {
            text: "Trees!",
          },
        },
      },
    },
    4: {
      badge: {
        bgColor: "bg-blue-pale-azure",
        imageUrl: "/images/profile-shirts-badges/shirt-sea.png",
        altImageText: "Sea Shirt Icon",
      },
      modal: {
        number: "02029",
        content: {
          shirtLabel: "Sea-Shirt",
          progressBarFillColor: "bg-blue-pale-azure",
          shirtTitleStyle: {
            text: "Sea Shirt",
            textColor: "text-blue-pale-azure",
            textShadowColor: "text-blue-deep",
          },
        },
        impact: {
          impactNumber: {
            text: "28",
            textColor: "text-blue-pale-azure",
            textShadowColor: "text-blue-deep",
          },
          impactLabel: {
            text: "Lbs removed!",
            textColor: "text-blue-pale-azure",
            textShadowColor: "text-blue-deep",
          },
        },
      },
    },
  };
